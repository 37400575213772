body {
    background-color: #161414;
    margin: 0 !important;
    padding: 0 !important;
}

* {
    font-family: 'Heebo', sans-serif !important;
}

/* Scrollbar width */
::-webkit-scrollbar {
    width: 4px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #242424; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #adadad; 
}